.authentication-bg {
	height: 100vh !important;
	background: linear-gradient(90deg, #4bb6ff, var(--primary-color)) !important;
}

.auth-card {
	z-index: 999999;
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 0 !important;
	word-wrap: break-word !important;
	background-clip: border-box !important;
	border: 0 solid #f6f6f6 !important;
	border-radius: 0.25rem !important;
	background: transparent !important;
	box-shadow: none !important;
	margin-right: 0 !important;
}

.auth-card ::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #fff;
	opacity: 1;
	/* Firefox */
}


.login-input {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	outline: 0;
	border: 1px solid rgba(255, 255, 255, 0.3);
	background-color: rgba(255, 255, 255, 0.1);
	width: 250px;
	border-radius: 3px;
	padding: 10px 15px;
	margin: 0 auto 10px auto;
	display: block;
	text-align: center;
	font-size: 18px;
	color: #fff;
	transition-duration: 0.25s;
	font-weight: 400;
}

.login-input.mobile {
	width: 100%;
	text-align: left;
	padding-left: 80px;
}

.login-input:focus {
	background-color: rgba(255, 255, 255, 0.2) !important;
	width: 100% !important;
	color: #fff !important;
}

.login-input-span {
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	color: #fff;
	height: 100%;
	display: flex;
	align-items: center;
	background: var(--primary-color);
	padding: 0 15px;
	font-size: 18px;
}

.login-input:hover {
	background-color: fade(white, 40%);
}

.login-input::placeholder {
	color: #fff;
	opacity: 0.4;
}

.login-button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	outline: 0;
	background-color: white;
	border: 0;
	padding: 10px 15px;
	color: #4AA173;
	border-radius: 3px;
	width: 250px;
	cursor: pointer;
	font-size: 18px;
	transition-duration: 0.25s;
}

.login-button:hover {
	background-color: #f5f7f9;
}

.lang-position {
	top: 15px;
	z-index: 99999;
	right: 15px;
	color: #fff;
}

.circles {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.circles li {
	position: absolute;
	display: block;
	list-style: none;
	width: 20px;
	height: 20px;
	background: rgba(0, 0, 0, 0.4);
	animation: animate 25s linear infinite;
	bottom: -150px;
}

.circles li:nth-child(1) {
	left: 25%;
	width: 80px;
	height: 80px;
	animation-delay: 0s;
}

.circles li:nth-child(2) {
	left: 10%;
	width: 20px;
	height: 20px;
	animation-delay: 2s;
	animation-duration: 12s;
}

.circles li:nth-child(3) {
	left: 70%;
	width: 20px;
	height: 20px;
	animation-delay: 4s;
}

.circles li:nth-child(4) {
	left: 40%;
	width: 60px;
	height: 60px;
	animation-delay: 0s;
	animation-duration: 18s;
}

.circles li:nth-child(5) {
	left: 65%;
	width: 20px;
	height: 20px;
	animation-delay: 0s;
}

.circles li:nth-child(6) {
	left: 75%;
	width: 110px;
	height: 110px;
	animation-delay: 3s;
}

.circles li:nth-child(7) {
	left: 35%;
	width: 150px;
	height: 150px;
	animation-delay: 7s;
}

.circles li:nth-child(8) {
	left: 50%;
	width: 25px;
	height: 25px;
	animation-delay: 15s;
	animation-duration: 45s;
}

.circles li:nth-child(9) {
	left: 20%;
	width: 15px;
	height: 15px;
	animation-delay: 2s;
	animation-duration: 35s;
}

.circles li:nth-child(10) {
	left: 85%;
	width: 150px;
	height: 150px;
	animation-delay: 0s;
	animation-duration: 11s;
}

@keyframes animate {
	0% {
		transform: translateY(0) rotate(0deg);
		opacity: 1;
		border-radius: 0;
	}

	100% {
		transform: translateY(-1000px) rotate(720deg);
		opacity: 0;
		border-radius: 50%;
	}
}

.react-select__menu {
	z-index: 10000;
}

.w-15 {
	width: 15% !important;
}

.small_input {
	width: 15% !important;
	text-align: end;
	height: 25px;
	padding: 0 10px !important;
}

.tox .tox-editor-header {
	z-index: 0 !important;
}

.table-control {
	position: relative;
}

.table-control-icon {
	position: absolute;
	right: -1px;
	top: -2px;
	cursor: pointer;
	background: #fff;
	z-index: 1;
}

.table-control-icon:hover {
	color: var(--primary);
}

.table-control-button {
	border: none;
	outline: none;
	width: 100%;
	height: 40px;
	border-radius: 8px !important;
}